import React, { FC, createContext, useState } from "react";
import { Day } from "src/types/Day";
import { Queue } from "src/types/Queue";

type HumanServiceContextData = {
  preQueueMessage: string;
  setPreQueueMessage: React.Dispatch<React.SetStateAction<string>>;
  emojis: boolean;
  setEmojis: React.Dispatch<React.SetStateAction<boolean>>;
  queueList: Queue[];
  setQueueList: React.Dispatch<React.SetStateAction<Queue[]>>;

  preServiceQuestionList: { [x: string]: string }[];
  setPreServiceQuestionList: React.Dispatch<
    React.SetStateAction<{ [x: string]: string }[]>
  >;

  twentyFourHours: boolean;
  setTwentyFourHours: React.Dispatch<React.SetStateAction<boolean>>;

  satisfactionSurvey: boolean;
  setSatisfactionSurvey: React.Dispatch<React.SetStateAction<boolean>>;

  sunday: Day;
  setSunday: React.Dispatch<React.SetStateAction<Day>>;
  monday: Day;
  setMonday: React.Dispatch<React.SetStateAction<Day>>;
  tuesday: Day;
  setTuesday: React.Dispatch<React.SetStateAction<Day>>;
  wednesday: Day;
  setWednesday: React.Dispatch<React.SetStateAction<Day>>;
  thursday: Day;
  setThursday: React.Dispatch<React.SetStateAction<Day>>;
  friday: Day;
  setFriday: React.Dispatch<React.SetStateAction<Day>>;
  saturday: Day;
  setSaturday: React.Dispatch<React.SetStateAction<Day>>;
  holiday: Day;
  setHoliday: React.Dispatch<React.SetStateAction<Day>>;
};

export const HumanServiceContext = createContext<HumanServiceContextData>(
  {} as HumanServiceContextData
);

export const HumanServiceProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [preQueueMessage, setPreQueueMessage] = useState("");
  const [emojis, setEmojis] = useState(false);
  const [queueList, setQueueList] = useState<Queue[]>([]);

  const [preServiceQuestionList, setPreServiceQuestionList] = useState<
    { [x: string]: string }[]
  >([]);

  const [twentyFourHours, setTwentyFourHours] = useState<boolean>(false);
  const [satisfactionSurvey, setSatisfactionSurvey] = useState<boolean>(false);

  const [sunday, setSunday] = useState<Day>({
    turns: [{ end: "", start: "" }],
    active: false,
  } as Day);

  const [monday, setMonday] = useState<Day>({
    turns: [{ end: "", start: "" }],
    active: false,
  } as Day);

  const [tuesday, setTuesday] = useState<Day>({
    turns: [{ end: "", start: "" }],
    active: false,
  } as Day);

  const [wednesday, setWednesday] = useState<Day>({
    turns: [{ end: "", start: "" }],
    active: false,
  } as Day);

  const [thursday, setThursday] = useState<Day>({
    turns: [{ end: "", start: "" }],
    active: false,
  } as Day);

  const [friday, setFriday] = useState<Day>({
    turns: [{ end: "", start: "" }],
    active: false,
  } as Day);

  const [saturday, setSaturday] = useState<Day>({
    turns: [{ end: "", start: "" }],
    active: false,
  } as Day);

  const [holiday, setHoliday] = useState<Day>({
    turns: [{ end: "", start: "" }],
    active: false,
  } as Day);

  return (
    <HumanServiceContext.Provider
      value={{
        preQueueMessage,
        setPreQueueMessage,
        emojis,
        setEmojis,
        queueList,
        setQueueList,

        preServiceQuestionList,
        setPreServiceQuestionList,

        satisfactionSurvey,
        setSatisfactionSurvey,

        twentyFourHours,
        setTwentyFourHours,

        sunday,
        setSunday,
        monday,
        setMonday,
        tuesday,
        setTuesday,
        wednesday,
        setWednesday,
        thursday,
        setThursday,
        friday,
        setFriday,
        saturday,
        setSaturday,
        holiday,
        setHoliday,
      }}
    >
      {children}
    </HumanServiceContext.Provider>
  );
};

export function useHumanServiceContext(): HumanServiceContextData {
  const context = React.useContext(HumanServiceContext);

  if (!context) {
    throw new Error(
      "use human service context must be used within an HumanServiceProvider"
    );
  }

  return context;
}
