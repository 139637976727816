/* eslint-disable react-hooks/exhaustive-deps */
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Tabs } from "src/types/Tabs";
import { validateTextInput } from "../../../../utils/validateTextInput";
import InputText from "../InputText";
import { MainContainer } from "./styles";
import tabsTexts from "src/texts/tabsTexts.json";

type InputProps = {
  onChangeServiceQuestionList: (
    question: { [x: string]: string },
    index: number
  ) => void;
  question: { [x: string]: string };
  setTabData: Dispatch<SetStateAction<Tabs>>;
  index: number;
};

export const InputPreServiceQuestion = ({
  question: queueQuestion,
  onChangeServiceQuestionList,
  index,
  setTabData,
}: InputProps) => {
  const [question, setQuestion] = useState(queueQuestion);
  const [isModified, setIsModified] = useState(false);

  const objectQueue = Object.keys(queueQuestion);

  const handleOnChange = (ev: Event) => {
    const input = ev.target as HTMLSelectElement | null;
    if (objectQueue) {
      setQuestion({ [objectQueue[0]]: input?.value as string });

      setIsModified(true);
    }
  };

  useEffect(() => {
    if (question) {
      onChangeServiceQuestionList(question, index);
    }
  }, [question]);

  useEffect(() => {
    if (isModified) {
      setTabData((current) => {
        return {
          ...current,
          isModified,
        };
      });
    }
  }, [isModified]);

  const validateInputPreServiceQuestion = (questions: {
    [x: string]: string;
  }) => {
    let isValid = true;

    objectQueue.forEach((queue) => {
      isValid = isValid && validateTextInput(questions[queue]);
    });
    return isValid;
  };

  useEffect(() => {
    setTabData((current) => {
      return {
        ...current,
        isInvalid: !validateInputPreServiceQuestion(queueQuestion),
      };
    });
  }, [queueQuestion]);

  return (
    <MainContainer>
      <InputText
        isTextArea={true}
        rows={4}
        value={objectQueue ? queueQuestion[objectQueue[0]] : " "}
        label={`Pergunta para fila ${objectQueue[0]}`}
        placeholder={tabsTexts.preService.preServiceQuestion.placeholder}
        onChange={handleOnChange}
        required={true}
        requiredErrorMessage={tabsTexts.error.requiredField}
      />
    </MainContainer>
  );
};
