import styled from "styled-components";

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-itens: center;
`;

export const ImageContainer = styled.div`
  padding: 80px 80px 80px 0px;
`;

export const Img = styled.img`
  width: 200px;
  @media screen and (min-width: 1280px) {
    width: 251px;
  }
  @media screen and (min-width: 1440px) {
    width: 262px;
  }

  @media screen and (min-width: 1600px) {
    width: 325px;
  }
`;
