import { DeleteAttendanceQueue } from "./data/delete/DeleteAttendanceQueue";
import { DeleteAttendant } from "./data/delete/DeleteAttentant";
import { DeleteResource } from "./data/delete/DeleteResource";

import { GetAllAttendanceQueues } from "./data/get/GetAllAttendanceQueues";
import { GetAllAttendanceQueuesSimplified } from "./data/get/GetAllAttendanceQueuesSimplified";
import { GetAllAttendants } from "./data/get/GetAllAttendants";
import { GetAllResources } from "./data/get/GetAllResources";
import { GetOneAttendanceQueue } from "./data/get/GetOneAttendanceQueue";
import { GetSpecificResource } from "./data/get/GetSpecificResource";

import { SetAttendanceQueue } from "./data/set/SetAttendanceQueue";
import { SetAttendanceRule } from "./data/set/SetAttendanceRule";
import { SetAttendant } from "./data/set/SetAttendant";
import { SetResource } from "./data/set/SetResource";

export class Requests {
  private api: any;

  constructor(api: any) {
    this.api = api;
  }

  private async executeRequest(data: any) {
    try {
      const response = await this.api.post("/commands", data);

      if (response.data.status === "failure") {
        console.error(response.data);
      }

      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  async setAttendanceQueue(
    ownerIdentity: string,
    queueName: string,
    guid: string
  ) {
    const data = SetAttendanceQueue({
      ownerIdentity,
      queueName,
      guid,
    });

    return await this.executeRequest(data);
  }

  async setAttendant(attendantIdentity: string, teams: string[], guid: string) {
    const data = SetAttendant({
      attendantIdentity,
      teams,
      guid,
    });

    return await this.executeRequest(data);
  }

  async setAttendanceRule(
    ownerIdentity: string,
    queueName: string,
    queueId: string,
    guid: string
  ) {
    const data = SetAttendanceRule({
      ownerIdentity,
      queueName,
      queueId,
      guid,
    });

    return await this.executeRequest(data);
  }

  async setResource(resourceValue: string, resourceName: string, guid: string) {
    const data = SetResource({
      resourceValue,
      resourceName,
      guid,
    });

    return await this.executeRequest(data);
  }

  async deleteResource(resourceName: string, guid: string) {
    const data = DeleteResource({
      resourceName,
      guid,
    });

    return await this.executeRequest(data);
  }

  async deleteAttendanceQueue(queueName: string, guid: string) {
    const data = DeleteAttendanceQueue({
      queueName,
      guid,
    });

    return await this.executeRequest(data);
  }

  async deleteAttendant(attendant: string, guid: string) {
    const data = DeleteAttendant({
      attendant,
      guid,
    });

    return await this.executeRequest(data);
  }

  async getAllAttendants(guid: string) {
    const data = GetAllAttendants({ guid });

    return await this.executeRequest(data);
  }

  async getAllAttendanceQueues(guid: string) {
    const data = GetAllAttendanceQueues({ guid });

    return await this.executeRequest(data);
  }

  async getAllAttendanceQueuesSimplified(guid: string) {
    const data = GetAllAttendanceQueuesSimplified({ guid });

    return await this.executeRequest(data);
  }

  async getOneAttendanceQueue(guid: string, queueName: string) {
    const data = GetOneAttendanceQueue({ guid, queueName });

    return await this.executeRequest(data);
  }

  async getAllResources(guid: string) {
    const data = GetAllResources({ guid });

    return await this.executeRequest(data);
  }

  async getSpecificResource(guid: string, resourceName: string) {
    const data = GetSpecificResource({ guid, resourceName });

    return await this.executeRequest(data);
  }
}
