import styled, { css } from 'styled-components';

interface MainContainerProps {
    lastInput: boolean;
}

export const MainContainer = styled.div<MainContainerProps>`
    display: flex;
    align-items: center;
    padding: 20px 0 20px 0;
    border-bottom: 1px solid #dce8ef;

    ${props =>
        props.lastInput &&
        css`
            border: none;
        `}

    //border: 1px solid green;

    bds-button-icon {
        margin-left: 10px;
    }
`;

export const TurnContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
