import { useHumanServiceContext } from "src/contexts/HumanServiceContext";

import { ColumnContainer, InputsContainer, TextContainer } from "../styles";

import tabsTexts from "src/texts/tabsTexts.json";
import InputPreServiceQuestion from "../components/InputPreServiceQuestion";
import { Dispatch, SetStateAction } from "react";
import { Tabs } from "src/types/Tabs";

import preServiceQuestionGif from "src/assets/images/preServiceQuestion.gif";
import { ImageContainer, Img, RowContainer } from "./styles";

type PreServiceQuestionProps = {
  setPreServiceQuestion: Dispatch<SetStateAction<Tabs>>;
};

export const PreServiceQuestion = ({
  setPreServiceQuestion,
}: PreServiceQuestionProps) => {
  const { preServiceQuestionList, setPreServiceQuestionList } =
    useHumanServiceContext();

  const handleChangeServiceQuestionList = (
    question: { [x: string]: string },
    index: number
  ) => {
    const newQuestionList = [...preServiceQuestionList];
    newQuestionList.splice(index, 1, question);
    setPreServiceQuestionList(newQuestionList);
  };

  return (
    <bds-tab-panel class="groups" group="attendance-pre-attendance">
      <RowContainer>
        <ColumnContainer>
          <TextContainer>
            <bds-typo variant="fs-16" bold="semi-bold" tag="p">
              {tabsTexts.preService.info}
            </bds-typo>

            <bds-typo variant="fs-16" bold="semi-bold" tag="p">
              {tabsTexts.preService.infoRepeatQuestion}
            </bds-typo>
          </TextContainer>

          <InputsContainer>
            {preServiceQuestionList &&
              preServiceQuestionList.map((question, i) => {
                return (
                  <InputPreServiceQuestion
                    index={i}
                    key={i}
                    question={question}
                    setTabData={setPreServiceQuestion}
                    onChangeServiceQuestionList={
                      handleChangeServiceQuestionList
                    }
                  />
                );
              })}
          </InputsContainer>
        </ColumnContainer>
        <ImageContainer>
          <Img src={preServiceQuestionGif} alt="Take Blip" />
        </ImageContainer>
      </RowContainer>
    </bds-tab-panel>
  );
};
