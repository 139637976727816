import styled, { css } from "styled-components";

interface MainContainerProps {
  isAdd: boolean;
}

export const MainContainer = styled.div<MainContainerProps>`
  display: flex;
  justify-content: end;
  width: 100%;

  ${(props) =>
    props.isAdd
      ? css`
          bds-button {
            width: 256px;
          }
        `
      : css`
          bds-button {
            margin-top: 32px;
          }
        `}
`;
