import { Day } from "src/types/Day";

export const setEarlierAndLaterValues = (
  days: Day | Day[],
  isWorkday: boolean
) => {
  if (isWorkday) {
    const workdayHoursEnd = (days as Day[]).map((day) => day.turns[0].end);
    const workdayHoursStart = (days as Day[]).map((day) => day.turns[0].start);

    const workdayEndSorted = workdayHoursEnd.sort().reverse();
    const workdayStartSorted = workdayHoursStart.sort();

    const workdayEndLater = workdayEndSorted.filter((end) => end !== "")[0];
    const workdayStartEarlier = workdayStartSorted.filter(
      (start) => start !== ""
    )[0];

    return {
      workdayEndLater,
      workdayStartEarlier,
    };
  } else {
    const daysHoursEnd = (days as Day).turns
      .filter((turn) => turn.end !== "")
      .map((turn) => turn.end);

    const daysHoursStart = (days as Day).turns
      .filter((turn) => turn.start !== "")
      .map((turn) => turn.start);

    const daysEndSorted = daysHoursEnd.sort().reverse();
    const daysStartSorted = daysHoursStart.sort();

    return {
      earlier: daysStartSorted[0],
      later: daysEndSorted[0],
    };
  }
};
