import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";

import InputChips from "../InputChips";
import Switch from "../Switch";

import { Day } from "src/types/Day";
import { Turn } from "src/types/Turn";
import TurnHours from "./TurnHours";
import { MainContainer, TurnContainer } from "./styles";
import { formatListDates, validateDates } from "src/utils/validateDates";
import tabsTexts from "src/texts/tabsTexts.json";

type AttendanceProps = {
  text: string;
  setDays: Dispatch<SetStateAction<Day>>;
  isHolidays?: boolean;
  day: Day;
  name: string;
  lastInput: boolean;
};

export const Attendance = ({
  text,
  setDays,
  isHolidays,
  name,
  lastInput,
  day,
}: AttendanceProps) => {
  const holidaysToArray = useMemo(
    () => (day.holidays === "" ? undefined : day.holidays?.split(",")),
    [day]
  );

  const chips = !holidaysToArray
    ? undefined
    : JSON.stringify(holidaysToArray, null, 1);

  const [holidays, setHolidays] = useState(isHolidays ? day.holidays : "");

  const handleInputsDisabled = async (event: Event) => {
    const input = event.currentTarget as HTMLBdsSwitchElement | null;
    const value = await input?.getValue();

    setDays((current) => {
      return {
        ...current,
        active: value as boolean,
        turns: [{ start: day.turns[0].start, end: day.turns[0].end }],
      };
    });
  };

  const handleHolidaysChips = async (ev: Event) => {
    const input = ev.currentTarget as HTMLBdsInputChipsElement | null;
    const chips = await input?.get();

    // Regex to extract only date values from the input (ex: 01/10 | from 01/10 - Something)
    const regex = /([0-3]{1}[0-9]{1})\/([01]{1}[0-9]{1})/g;

    // Get the chips, parse to string, match the regex and parse the results to a string.
    const result =
      (chips?.toString().match(regex)?.toString() as string) || undefined;
    setHolidays(result);
  };

  const handleAddTurn = () => {
    setDays((current) => {
      return {
        ...current,
        turns: [...day.turns, { end: "", start: "" }],
      };
    });
  };

  const handleRemoveTurn = (i: number) => {
    const turns = [...day.turns];
    turns.splice(i, 1);

    setDays((current) => {
      return { ...current, turns };
    });
  };

  useEffect(() => {
    if (isHolidays) {
      setDays((current) => {
        return {
          ...current,
          holidays,
        };
      });
    }
  }, [holidays, isHolidays, setDays]);

  const IsInvalidHolidays = (day: Day, chips: string | undefined) => {
    if (!day.active) {
      return false;
    } else if (chips === undefined) {
      return true;
    } else {
      const holidays = formatListDates(chips);
      return holidays !== undefined && !validateDates(holidays);
    }
  };

  return (
    <>
      <MainContainer lastInput={lastInput}>
        <Switch
          checked={day.active}
          onClick={handleInputsDisabled}
          name={name}
          text={text}
          isEmojiSwitch={false}
        />

        <TurnContainer>
          {day.turns.map((turn, i) => (
            <TurnHours
              handleAddTurn={handleAddTurn}
              handleRemoveTurn={handleRemoveTurn}
              turns={day.turns as Turn[]}
              turn={turn as Turn}
              isDisabled={!day.active}
              setDays={setDays}
              key={i}
              i={i}
            />
          ))}
        </TurnContainer>
      </MainContainer>

      {isHolidays && (
        <InputChips
          disabled={!day.active}
          duplicated={false}
          chips={day.active ? chips : ""}
          onChange={handleHolidaysChips}
          danger={IsInvalidHolidays(day, chips)}
          errorMessage={tabsTexts.schedules.error.requiredHolidays}
        />
      )}
    </>
  );
};
