import { validateHour } from "src/utils/validateHours";
import InputText from "../InputText";

import { MainContainer } from "./styles";

type SelectProps = {
  disabled: boolean;
  setAttendance: (obj: string) => void;
  text: string;
  end: number;
  value: string;
};

export const SelectHour = ({
  disabled,
  setAttendance,
  text,
  end,
  value,
}: SelectProps) => {
  const handleChange = (ev: Event) => {
    const input = ev.target as HTMLSelectElement;
    const inputValue = input.value
      .replace(/\D/g, "")
      .match(/(\d{0,2})(\d{0,2})/);
    if (inputValue !== null && inputValue !== undefined)
      input.value = !inputValue[2]
        ? inputValue[1]
        : `${inputValue[1]}:${inputValue[2]}`;

    setAttendance(input.value as string);

    ev.stopImmediatePropagation();
  };

  return (
    <MainContainer end={end}>
      <bds-typo variant="fs-14">{text}</bds-typo>

      <InputText
        value={value}
        onChange={handleChange}
        disabled={disabled}
        required={true}
        danger={value !== "" && !validateHour(value)}
      />
    </MainContainer>
  );
};
