import { useHumanServiceContext } from "src/contexts/HumanServiceContext";
import InputMessageEditable from "../components/InputMessageEditable";
import { Switch } from "../components/Switch/Switch";
import {
  ColumnContainer,
  HeaderAttendanceQueueContainer,
  InputsContainer,
  TextContainer,
} from "../styles";

import tabsTexts from "src/texts/tabsTexts.json";
import QueueFields from "../components/QueueFields";
import { Dispatch, SetStateAction, useMemo } from "react";
import { Tabs } from "src/types/Tabs";
import { Requests } from "src/services/requests/Requests";

import queueGif from "src/assets/images/queue.gif";
import { ImageContainer, Img, RowContainer } from "./styles";

type AttendanceQueueProps = {
  setIsRemoved: Dispatch<SetStateAction<boolean>>;
  idxRemoved: number;
  setIdxRemoved: Dispatch<SetStateAction<number>>;
  setAttendanceQueue: Dispatch<SetStateAction<Tabs>>;
  isCanceledAction: boolean;
  setIsCanceledAction: Dispatch<SetStateAction<boolean>>;
  requestObject?: Requests;
};

export const AttendanceQueue = ({
  setIsRemoved,
  idxRemoved,
  setIdxRemoved,
  setAttendanceQueue,
  isCanceledAction,
  setIsCanceledAction,
  requestObject,
}: AttendanceQueueProps) => {
  const {
    preQueueMessage,
    setPreQueueMessage,
    emojis,
    setEmojis,
    queueList,
    setQueueList,
  } = useHumanServiceContext();

  const memoizedQueueFields = useMemo(
    () => (
      <QueueFields
        setIsRemoved={setIsRemoved}
        setIdxRemoved={setIdxRemoved}
        idxRemoved={idxRemoved}
        queueList={queueList}
        setQueueList={setQueueList}
        setTabData={setAttendanceQueue}
        setIsCanceledAction={setIsCanceledAction}
        isCanceledAction={isCanceledAction}
        requestObject={requestObject}
      />
    ),
    [idxRemoved, isCanceledAction, queueList, requestObject]
  );

  const handleChangePreQueueMessage = (ev: Event) => {
    const input = ev.target as HTMLBdsInputElement | null;
    setPreQueueMessage(input?.value as string);
  };

  const handleSetEmojis = async (event: Event) => {
    const input = event.currentTarget as HTMLBdsSwitchElement | null;
    const value = await input?.getValue();

    setEmojis(!!value);
  };

  return (
    <bds-tab-panel class="groups" group="attendance-queue">
      <RowContainer>
        <ColumnContainer>
          <TextContainer>
            <bds-typo variant="fs-16" bold="bold" tag="p">
              {tabsTexts.attendanceQueue.info}
            </bds-typo>
          </TextContainer>

          <HeaderAttendanceQueueContainer>
            <InputMessageEditable
              disabled={false}
              onChange={handleChangePreQueueMessage}
              label={tabsTexts.attendanceQueue.preQueueMessage.label}
              message={preQueueMessage}
              rows={3}
              required={true}
              requiredErrorMessage={tabsTexts.error.requiredField}
            />

            <Switch
              text={tabsTexts.attendanceQueue.emojis}
              name="emojis"
              checked={emojis}
              isEmojiSwitch={true}
              onClick={handleSetEmojis}
            />
          </HeaderAttendanceQueueContainer>

          <InputsContainer> {memoizedQueueFields}</InputsContainer>
        </ColumnContainer>
        <ImageContainer>
          <Img src={queueGif} alt="Take Blip" />
        </ImageContainer>
      </RowContainer>
    </bds-tab-panel>
  );
};
