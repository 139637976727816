import { Dispatch, SetStateAction } from "react";
import { Tabs } from "src/types/Tabs";

import ModalSuccess from "../HumanService/components/ModalSuccess";

type AlertsTabProps = {
  attendanceHours: {
    isOpenAlertModify?: boolean | undefined;
    isOpenModalSuccess?: boolean | undefined;
  };
  attendanceQueue: {
    isOpenAlertModify?: boolean | undefined;
    isOpenModalSuccess?: boolean | undefined;
  };
  preServiceQuestion: {
    isOpenAlertModify?: boolean | undefined;
    isOpenModalSuccess?: boolean | undefined;
  };
  setAttendanceHours: Dispatch<SetStateAction<Tabs>>;
  setAttendanceQueue: Dispatch<SetStateAction<Tabs>>;
  setPreServiceQuestion: Dispatch<SetStateAction<Tabs>>;
  handleReturnToPreviousTab: () => void;
};

export const AlertsTab = ({
  attendanceHours,
  attendanceQueue,
  preServiceQuestion,
  setAttendanceHours,
  setAttendanceQueue,
  setPreServiceQuestion,
}: AlertsTabProps) => {
  return (
    <>
      {attendanceHours && attendanceHours.isOpenModalSuccess && (
        <ModalSuccess
          setTabData={setAttendanceHours}
          open={attendanceHours.isOpenModalSuccess}
          text="O horários foi alterado com sucesso."
        />
      )}
      {attendanceQueue && attendanceQueue.isOpenModalSuccess && (
        <ModalSuccess
          setTabData={setAttendanceQueue}
          open={attendanceQueue.isOpenModalSuccess}
          text="Fila de atendimento foi alterada com sucesso.
					Atenção: Lembre-se de revisar também a pergunta pré-atendimento."
        />
      )}
      {preServiceQuestion && preServiceQuestion.isOpenModalSuccess && (
        <ModalSuccess
          setTabData={setPreServiceQuestion}
          open={preServiceQuestion.isOpenModalSuccess}
          text="Pré-atendimento foi alterada com sucesso."
        />
      )}
    </>
  );
};
