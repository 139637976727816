interface SetAttendanceRuleProps {
  ownerIdentity: string;
  queueId: string;
  queueName: string;
  guid: string;
}

export const SetAttendanceRule = ({
  ownerIdentity,
  queueId,
  queueName,
  guid,
}: SetAttendanceRuleProps) => {
  return {
    id: guid,
    to: "postmaster@desk.msging.net",
    method: "set",
    type: "application/vnd.iris.desk.rule+json",
    uri: "/rules",
    resource: {
      ownerIdentity,
      id: queueId,
      team: queueName,
      title: "Attendance Rule - " + queueName,
      conditions: [
        {
          property: "Contact.Extras.teams",
          relation: "Contains",
          values: [queueName],
          extrasProperty: "teams",
        },
      ],
      isActive: true,
      opened: true,
      operator: "Or",
      repeatConditionsBody: false,
      valuesEmpty: false,
    },
  };
};
