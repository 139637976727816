import { Day } from "src/types/Day";

export const setWorkSchedule = (days: (string | Day)[][]) => {
  const workSchedule: any = [];
  const daysPortugueseNames: string[] = [];

  days.forEach((day) => {
    const onlyDay = day[1] as Day;

    const invalidDay = onlyDay.turns.find(
      (turn) => turn.end === "" || turn.start === ""
    );

    if (!invalidDay) {
      let portugueseName;
      let num;
      switch (day[0]) {
        case "Sunday":
          portugueseName = "Domingo";
          num = 0;
          daysPortugueseNames.push(portugueseName);
          break;
        case "Monday":
          portugueseName = "Segunda";
          num = 1;
          daysPortugueseNames.push(portugueseName);
          break;
        case "Tuesday":
          portugueseName = "Terça";
          num = 2;
          daysPortugueseNames.push(portugueseName);
          break;
        case "Wednesday":
          portugueseName = "Quarta";
          num = 3;
          daysPortugueseNames.push(portugueseName);
          break;
        case "Thursday":
          portugueseName = "Quinta";
          num = 4;
          daysPortugueseNames.push(portugueseName);
          break;
        case "Friday":
          portugueseName = "Sexta";
          num = 5;
          daysPortugueseNames.push(portugueseName);
          break;
        case "Saturday":
          portugueseName = "Sábado";
          num = 6;
          daysPortugueseNames.push(portugueseName);
          break;
      }

      workSchedule.push({
        num,
        name: day[0],
        portugueseName,
        workTime: (day[1] as Day).turns,
      });
    }
  });

  return {
    workSchedule,
    daysPortugueseNames,
  };
};
