import styled, { css } from "styled-components";

interface InputTextContainerProps {
  queueListLength: number;
}

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  width: 400px;
`;

export const InputTextContainer = styled.div<InputTextContainerProps>`
  ${(props) =>
    props.queueListLength == 1 &&
    css`
      width: 400px;
    `}
  ${(props) =>
    props.queueListLength > 1 &&
    css`
      width: 330px;
    `}
`;

export const ButtonIconContainer = styled.div`
  margin-left: auto;
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 400px;
  justify-content: flex-end;
`;

export const InputContainer = styled.div`
  width: 400px;
  margin: 20px 0 30px 0;
`;
