import { RESOURCES } from "src/constants/ResourcesNames";
import { TEXT_TYPE } from "src/constants/Application";
import { api } from "src/services/requests/Api";
import { Requests } from "src/services/requests/Requests";
import { RouterData } from "src/types/PluginProps";
import { Tabs } from "src/types/Tabs";
import saveResourceData from "./saveResourceData";

import { LoggedAreaTracks } from "../../services/trackings/handleSendTrackings";
import { SendTrackProps } from "src/types/SendTrackProps";

import tabsTexts from "src/texts/tabsTexts.json";

interface saveSatisfactionSurveyProps {
  setSatisfactionSurveyTab: React.Dispatch<React.SetStateAction<Tabs>>;
  satisfactionSurvey: boolean;
  trackConfig: SendTrackProps;
  routerData: RouterData;
  keyRouter: string;
}

export const saveSatisfactionSurvey = async ({
  setSatisfactionSurveyTab,
  satisfactionSurvey,
  trackConfig,
  routerData,
  keyRouter,
}: saveSatisfactionSurveyProps) => {
  const loggedAreaTracks = new LoggedAreaTracks();

  const request = new Requests(api(routerData.shortName, keyRouter));

  const responses = await saveResourceData(request, [
    {
      name: RESOURCES.SATISFACTION_SURVEY,
      value: satisfactionSurvey.toString(),
      type: TEXT_TYPE,
    },
  ]);

  setSatisfactionSurveyTab((current) => {
    return {
      ...current,
      isOpenModalSuccess: true,
    };
  });

  await loggedAreaTracks.sendTrackClickButtonSave({
    screenName: tabsTexts.satisfactionSurvey.title,
    msgWarning: "none",
    ...trackConfig,
    success: true,
    error: responses.length === 0 ? "The data was not saved" : "none",
  });

  return responses;
};
