import { validatePreServiceQuestion } from "src/utils/validatePreServiceQuestion";
import { Tabs } from "src/types/Tabs";
import { Requests } from "src/services/requests/Requests";
import { api } from "src/services/requests/Api";
import { RouterData } from "src/types/PluginProps";
import { RESOURCES } from "src/constants/ResourcesNames";
import saveResourceData from "./saveResourceData";
import { LoggedAreaTracks } from "../../services/trackings/handleSendTrackings";
import { WarningConstants } from "../../../../constants/WarningsConstants";
import { SendTrackProps } from "src/types/SendTrackProps";

interface SavePreServiceQuestionsProps {
  setPreServiceQuestion: React.Dispatch<React.SetStateAction<Tabs>>;
  preServiceQuestions: { [x: string]: string }[];
  routerData: RouterData;
  trackConfig: SendTrackProps;
  keyRouter: string;
}

export const savePreServiceQuestions = async ({
  preServiceQuestions,
  setPreServiceQuestion,
  routerData,
  trackConfig,
  keyRouter,
}: SavePreServiceQuestionsProps) => {
  const loggedAreaTracks = new LoggedAreaTracks();

  if (validatePreServiceQuestion(preServiceQuestions)) {
    const request = new Requests(api(routerData.shortName, keyRouter));
    const responses = await saveResourceData(request, [
      {
        name: RESOURCES.PRE_SERVICE_QUESTIONS,
        type: "text/plain",
        value: JSON.stringify(preServiceQuestions),
      },
    ]);

    setPreServiceQuestion((current) => {
      return {
        ...current,
        canSave: true,
        valuesToBeSaved: preServiceQuestions,
      };
    });

    setPreServiceQuestion((current) => {
      return {
        ...current,
        isOpenModalSuccess: true,
      };
    });

    await loggedAreaTracks.sendTrackClickButtonSave({
      screenName: "Pré-atendimento",
      msgWarning: "none",
      ...trackConfig,
      success: true,
      error: responses.length === 0 ? "The data was not saved" : "none",
    });

    return responses;
  } else {
    setPreServiceQuestion((current) => {
      return {
        ...current,
        isInvalid: true,
        isOpenWarning: true,
      };
    });

    await loggedAreaTracks.sendTrackClickButtonSave({
      screenName: "Pré-atendimento",
      msgWarning: WarningConstants.PRESERVICEQUESTIONWARNING,
      ...trackConfig,
      success: false,
      error: `user input error`,
    });
  }
};
