import Guid from "guid";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";

import {
  ButtonsContainer,
  LoadingContainer,
  MainContainer,
  SingleButtonContainer,
  TabsPanelContainer,
} from "./styles";

import Button from "./components/Button";

import { Day } from "../../types/Day";
import { Queue } from "../../types/Queue";

import { useAppContext } from "../../contexts/AppContext";
import { useHumanServiceContext } from "../../contexts/HumanServiceContext";

import { Resource } from "src/types/Resource";
import { Tabs } from "src/types/Tabs";

import { resetDays } from "./services/reset/resetDays";
import { resetPreServiceQuestions } from "./services/reset/resetPreServiceQuestions";
import { resetQueues } from "./services/reset/resetQueues";
import { resetSatisfactionSurvey } from "./services/reset/resetSatisfactionSurvey";

import { RESOURCES } from "src/constants/ResourcesNames";
import tabsTexts from "src/texts/tabsTexts.json";
import { mountKey } from "src/utils/mountKey";
import { saveDays } from "./services/save/saveDays";
import { saveTwentyFourHourService } from "./services/save/saveTwentyFourHourService";
import { savePreServiceQuestions } from "./services/save/savePreServiceQuestions";
import { saveQueues } from "./services/save/saveQueues";
import { saveSatisfactionSurvey } from "./services/save/saveSatisfactionSurvey";

import { api } from "src/services/requests/Api";
import { Requests } from "src/services/requests/Requests";

import { WarningTab } from "../alerts/warningTab";
import { AlertsTab } from "../alerts/alertsTab";
import { TEXT_TYPE } from "src/constants/Application";

import { LoggedAreaTracks } from "src/pages/HumanService/services/trackings/handleSendTrackings";
import { SendTrackProps } from "src/types/SendTrackProps";
import { getAttendanceQueues } from "./services/getAttendanceQueues";
import { validateTextInput } from "../../utils/validateTextInput";
import { validateHours } from "src/utils/validateHours";
import { validateDates } from "src/utils/validateDates";
import { PreServiceQuestion } from "./tabs/PreServiceQuestion";
import { AttendanceQueue } from "./tabs/AttendanceQueue";
import { OpeningHours } from "./tabs/OpeningHours";
import { SatisfactionSurvey } from "./tabs/SatisfactionSurvey";

export const HumanService = () => {
  const loggedAreaTracks = new LoggedAreaTracks();

  const { userData, routerData, activationOption } = useAppContext();

  const {
    preQueueMessage,
    setPreQueueMessage,
    emojis,
    setEmojis,
    queueList,
    setQueueList,

    preServiceQuestionList,
    setPreServiceQuestionList,

    satisfactionSurvey,
    setSatisfactionSurvey,

    twentyFourHours,
    setTwentyFourHours,

    sunday,
    setSunday,
    monday,
    setMonday,
    tuesday,
    setTuesday,
    wednesday,
    setWednesday,
    thursday,
    setThursday,
    friday,
    setFriday,
    saturday,
    setSaturday,
    holiday,
    setHoliday,
  } = useHumanServiceContext();

  const [keyRouter, setKeyRouter] = useState("");
  const [keyDesk, setKeyDesk] = useState("");
  const [request, setRequest] = useState<Requests | undefined>();
  const [requestRouterResources, setRequestWithResources] = useState<
    Requests | undefined
  >();

  const [isCanceledAction, setIsCanceledAction] = useState(false);

  useEffect(() => {
    const transhipmentKey =
      routerData.skillTransbordo &&
        routerData.skillTransbordo.accessKey &&
        routerData.skillTransbordo.shortName
        ? mountKey(
          routerData.skillTransbordo.shortName,
          routerData.skillTransbordo.accessKey
        )
        : undefined;
    setKeyDesk(transhipmentKey || "");

    const routerKey =
      routerData.shortName && routerData.accessKey
        ? mountKey(routerData.shortName, routerData.accessKey)
        : undefined;
    setKeyRouter(routerKey || "");

    const request = transhipmentKey
      ? new Requests(api(routerData.skillTransbordo.shortName, transhipmentKey))
      : undefined;
    setRequest(request);

    const requestRouterResources = routerKey
      ? new Requests(api(routerData.shortName, routerKey))
      : undefined;
    setRequestWithResources(requestRouterResources);
  }, [routerData]);

  const [resources, setResources] = useState<Resource[]>([]);
  const [previousTab, setPreviousTab] = useState("");

  const [isRemoved, setIsRemoved] = useState(false);
  const [idxRemoved, setIdxRemoved] = useState(0);
  const [cancelWarning, setCancelWarning] = useState(false);

  const [loadingResources, setLoadingResources] = useState(true);

  const [attendanceHours, setAttendanceHours] = useState<Tabs>({
    isInvalid: false,
    isOpenWarning: false,
    isModified: false,
    isOpenModalSuccess: false,
    isOpenAlertModify: false,
    isLoading: false,
    handleCloseWarning: () => {
      setAttendanceHours((current) => {
        return {
          ...current,
          isOpenWarning: false,
        };
      });
    },
  });

  const [satisfactionSurveyTab, setSatisfactionSurveyTab] = useState<Tabs>({
    isInvalid: false,
    isOpenWarning: false,
    isModified: false,
    isOpenModalSuccess: false,
    isOpenAlertModify: false,
    isLoading: false,
    handleCloseWarning: () => {
      setSatisfactionSurveyTab((current) => {
        return {
          ...current,
          isOpenWarning: false,
        };
      });
    },
  });

  const [attendanceQueue, setAttendanceQueue] = useState<Tabs>({
    isInvalid: false,
    isOpenWarning: false,
    isModified: false,
    isOpenModalSuccess: false,
    isLoading: false,
    isOpenAlertModify: false,
    handleCloseWarning: () => {
      setAttendanceQueue((current) => {
        return {
          ...current,
          isOpenWarning: false,
        };
      });
    },
  });

  const [preServiceQuestion, setPreServiceQuestion] = useState<Tabs>({
    isInvalid: false,
    isOpenWarning: false,
    isModified: false,
    isOpenModalSuccess: false,
    isLoading: false,
    isOpenAlertModify: false,
    handleCloseWarning: () => {
      setPreServiceQuestion((current) => {
        return {
          ...current,
          isOpenWarning: false,
        };
      });
    },
  });

  const searchResources = useCallback(
    async (variablesResource: string[]) => {
      const resourcesFromBot = [];

      for (const resource of variablesResource) {
        const resourceValue = await requestRouterResources?.getSpecificResource(
          Guid.raw(),
          resource
        );
        const newResource = {
          name: decodeURIComponent(resource),
          value: resourceValue.resource,
          type: resourceValue.type,
        };
        resourcesFromBot.push(newResource);
      }
      setResources(resourcesFromBot);
      setLoadingResources(false);
    },
    [requestRouterResources]
  );

  const getAndSetResources = useCallback(
    async (step: string) => {
      switch (step) {
        case "Pré-atendimento":
          searchResources([RESOURCES.PRE_SERVICE_QUESTIONS]);
          break;
        case "Fila de atendimento":
          searchResources([
            RESOURCES.MESSAGES.PRE_QUEUE,
            RESOURCES.EMOJI,
            RESOURCES.PRE_SERVICE_QUESTIONS,
          ]);
          const teams = await getAttendanceQueues(request);

          setQueueList(teams);
          break;
        case tabsTexts.satisfactionSurvey.title:
          searchResources([RESOURCES.SATISFACTION_SURVEY]);
          break;
        case "Horários":
          searchResources([
            RESOURCES.DAYS.SATURDAY.END,
            RESOURCES.DAYS.SATURDAY.START,
            RESOURCES.MESSAGES.WORKDAY_WITH_SATURDAY,
            RESOURCES.DAYS.SUNDAY.END,
            RESOURCES.DAYS.SUNDAY.START,
            RESOURCES.DAYS.HOLIDAY.START,
            RESOURCES.DAYS.HOLIDAY.END,
            RESOURCES.HOLIDAY_ATTENDANCE,
            RESOURCES.HOLIDAYS,
            RESOURCES.WORK_SCHEDULE,
            RESOURCES.WORK_DAYS,
            RESOURCES.TWENTY_FOUR_HOURS,
          ]);
          break;
      }
    },
    [request, requestRouterResources, searchResources]
  );

  const updateResources = (tab: string) => {
    switch (tab) {
      case "Pré-atendimento":
        setResources([
          {
            name: RESOURCES.PRE_SERVICE_QUESTIONS,
            type: "text/plain",
            value: JSON.stringify(preServiceQuestionList),
          },
        ]);
        break;
      case "Fila de atendimento":
        setResources([
          {
            name: RESOURCES.QUEUES,
            type: TEXT_TYPE,
            value: JSON.stringify(queueList),
          },
          {
            name: RESOURCES.MESSAGES.PRE_QUEUE,
            type: TEXT_TYPE,
            value: preQueueMessage,
          },
          {
            name: RESOURCES.EMOJI,
            type: "text/plain",
            value: emojis.toString(),
          },
          {
            name: RESOURCES.PRE_SERVICE_QUESTIONS,
            type: "text/plain",
            value: JSON.stringify(preServiceQuestionList),
          },
        ]);
        break;
      case tabsTexts.satisfactionSurvey.title:
        setResources([
          {
            name: RESOURCES.SATISFACTION_SURVEY,
            type: "text/plain",
            value: satisfactionSurvey.toString(),
          },
        ]);
        break;
      case "Horários":
        getAndSetResources("Horários");
        break;
    }
  };

  const handleCancelAlert = async () => {
    setCancelWarning(!cancelWarning);

    if (!previousTab) {
      setPreviousTab(tabsTexts.attendanceQueue.title);
    }

    sendTrackingButtonHandleCancelAlert(
      previousTab ? previousTab : tabsTexts.attendanceQueue.title,
      cancelWarning
    );
  };

  const sendTrackingButtonHandleCancelAlert = async (
    screenName: string,
    cancelWarning: boolean
  ) => {
    if (!cancelWarning) {
      await loggedAreaTracks.sendTrackClickButtonEdit({
        merchantName: userData.name,
        screenName,
        activationOption,
      });
    }
  };

  const messageButtonCancel = `Suas alterações de ${previousTab} não serão salvas.`;

  const sendTrackingHandlePressYes = async () => {
    await loggedAreaTracks.sendTrackAlertOpen({
      merchantName: userData.name,
      screenName: previousTab ? previousTab : tabsTexts.attendanceQueue.title,
      activationOption,
      msgWarning: messageButtonCancel,
    });
  };

  const configTrackingSave = (): SendTrackProps => {
    return {
      merchantName: userData.name,
      numQueue: queueList.length,
      service24h: twentyFourHours,
      statusskill: `pesquisa satisfacao ${satisfactionSurvey}`,
    };
  };

  const handleClickButton = async (option: string) => {
    const tabs = document.getElementsByTagName("bds-tab");

    const optionCancel = "cancel";
    const optionSave = "save";

    for (let i = 0; i < tabs.length; i++) {
      if (/bds-tab--selected/g.test(tabs[i].className)) {
        switch (tabs[i].label) {
          case tabsTexts.satisfactionSurvey.title:
            if (option === optionCancel) {
              resetSatisfactionSurvey({ resources, setSatisfactionSurvey });
            } else if (option === optionSave) {
              setSatisfactionSurveyTab((current) => {
                return {
                  ...current,
                  isLoading: true,
                  isOpenModalSuccess: false,
                };
              });

              saveSatisfactionSurvey({
                routerData,
                trackConfig: configTrackingSave(),
                satisfactionSurvey,
                setSatisfactionSurveyTab,
                keyRouter: keyRouter || "",
              }).then(() => {
                setSatisfactionSurveyTab((current) => {
                  return {
                    ...current,
                    isLoading: false,
                  };
                });
                updateResources(tabsTexts.satisfactionSurvey.title);
              });
            }
            break;
          case "Horários":
            if (option === optionCancel) {
              resetDays({
                resources,
                setSunday,
                setMonday,
                setTuesday,
                setWednesday,
                setThursday,
                setFriday,
                setSaturday,
                setHoliday,
                setTwentyFourHours,
              });
            } else if (option === optionSave) {
              setAttendanceHours((current) => {
                return {
                  ...current,
                  isLoading: true,
                  isOpenModalSuccess: false,
                };
              });

              if (twentyFourHours) {
                saveTwentyFourHourService({
                  routerData,
                  trackConfig: configTrackingSave(),
                  twentyFourHours,
                  setAttendanceHours,
                  keyRouter: keyRouter || "",
                }).then(() => {
                  setAttendanceHours((current) => {
                    return {
                      ...current,
                      isLoading: false,
                    };
                  });
                  updateResources("Horários");
                });
              } else {
                saveDays({
                  routerData,
                  trackConfig: configTrackingSave(),
                  days: [
                    ["Sunday", sunday],
                    ["Monday", monday],
                    ["Tuesday", tuesday],
                    ["Wednesday", wednesday],
                    ["Thursday", thursday],
                    ["Friday", friday],
                    ["Saturday", saturday],
                    ["holiday", holiday],
                  ],
                  twentyFourHours,
                  setAttendanceHours,
                  keyRouter: keyRouter || "",
                }).then(() => {
                  setAttendanceHours((current) => {
                    return {
                      ...current,
                      isLoading: false,
                    };
                  });
                  updateResources("Horários");
                });
              }
            }
            break;
          case "Fila de atendimento":
            if (option === optionCancel) {
              const teams = await getAttendanceQueues(request);

              setQueueList(teams);

              resetQueues({
                resources,
                setEmojis,
                setPreQueueMessage,
              });
            } else if (option === optionSave) {
              setAttendanceQueue((current) => {
                return {
                  ...current,
                  isLoading: true,
                  isOpenModalSuccess: false,
                };
              });

              const oldQueues = await getAttendanceQueues(request);

              saveQueues({
                deskKey: keyDesk || "",
                routerKey: keyRouter || "",
                routerData,
                trackConfig: configTrackingSave(),
                newQueues: queueList,
                oldQueues: oldQueues,
                preServiceQuestionList,
                setAttendanceQueue,
                preQueueMessage,
                useEmojis: emojis,
              }).then(() => {
                setAttendanceQueue((current) => {
                  return {
                    ...current,
                    isLoading: false,
                  };
                });
                updateResources("Fila de atendimento");
              });
            }
            break;
          case "Pré-atendimento":
            if (option === optionCancel) {
              resetPreServiceQuestions({
                setPreServiceQuestionList,
                resources,
              });
            } else if (option === optionSave) {
              setPreServiceQuestion((current) => {
                return {
                  ...current,
                  isLoading: true,
                  isOpenModalSuccess: false,
                };
              });
              savePreServiceQuestions({
                preServiceQuestions: preServiceQuestionList,
                setPreServiceQuestion,
                routerData,
                trackConfig: configTrackingSave(),
                keyRouter: keyRouter || "",
              }).then(() => {
                setPreServiceQuestion((current) => {
                  return {
                    ...current,
                    isLoading: false,
                  };
                });
                updateResources("Pré-atendimento");
              });
            }
            break;
        }
      }
    }
  };

  const setInitialInputValues = async () => {
    setQueueList([]);
    if (resources.length > 0) {
      const teams = await getAttendanceQueues(request);
      setQueueList(teams);
      resetDays({
        resources,
        setSunday,
        setMonday,
        setTuesday,
        setWednesday,
        setThursday,
        setFriday,
        setSaturday,
        setHoliday,
        setTwentyFourHours,
      });

      resetQueues({
        resources,
        setEmojis,
        setPreQueueMessage,
      });

      resetPreServiceQuestions({
        setPreServiceQuestionList,
        resources,
      });

      resetSatisfactionSurvey({ resources, setSatisfactionSurvey });
    }
  };

  const handleCancelChanges = () => {
    setIsCanceledAction(true);
    handleClickButton("cancel");
    setCancelWarning(!cancelWarning);

    sendTrackingHandlePressYes();
  };

  const handleSaveChanges = () => {
    handleClickButton("save");
  };

  const handlePreviousTab = (label: string) => {
    const tabs = document.getElementsByTagName("bds-tab");

    for (let i = 0; i < tabs.length; i++) {
      if (/bds-tab--selected/g.test(tabs[i].className)) {
        setPreviousTab(label);
        getAndSetResources(label);
      };

      if (tabs[i].label === label) {
        sendTrackTab(
          userData.fullName || userData.name,
          tabs[i].group
        );
      }
    }
  };

  const sendTrackTab = async (merchantName: string, screenName: string) => {
    const loggedAreaTracks = new LoggedAreaTracks();
    await loggedAreaTracks.sendTrackTab({ merchantName, screenName });
  };

  const handleOpenAlertModify = () => {
    switch (previousTab) {
      case "Horários":
        if (attendanceHours.isModified) {
          setAttendanceHours((current) => {
            return {
              ...current,
              isOpenAlertModify: true,
            };
          });
        }
        break;
      case "Fila de atendimento":
        if (attendanceQueue.isModified) {
          setAttendanceQueue((current) => {
            return {
              ...current,
              isOpenAlertModify: true,
            };
          });
        }
        break;
      case "Pré-atendimento":
        if (preServiceQuestion.isModified) {
          setPreServiceQuestion((current) => {
            return {
              ...current,
              isOpenAlertModify: true,
            };
          });
        }
        break;
    }
  };

  const handleReturnToPreviousTab = () => {
    const tabs = document.getElementsByTagName("bds-tab");

    for (let i = 0; i < tabs.length; i++) {
      if (tabs[i].label === previousTab) {
        tabs[i].click();
      }
    }
  };

  const tabsIsLoading = () => {
    return (
      (attendanceHours && attendanceHours.isLoading) ||
      (attendanceQueue && attendanceQueue.isLoading) ||
      (preServiceQuestion && preServiceQuestion.isLoading) ||
      (satisfactionSurveyTab && satisfactionSurveyTab.isLoading)
    );
  };

  const tabsIsInvalid = () => {
    switch (previousTab) {
      case "Pré-atendimento":
        return preServiceQuestion && preServiceQuestion.isInvalid;
      case tabsTexts.satisfactionSurvey.title:
        return satisfactionSurveyTab && satisfactionSurveyTab.isInvalid;
      case "Horários":
        return attendanceHours && attendanceHours.isInvalid;
      default:
        return attendanceQueue && attendanceQueue.isInvalid;
    }
  };

  useLayoutEffect(() => {
    if (requestRouterResources) {
      getAndSetResources(tabsTexts.attendanceQueue.title);
      setPreviousTab(tabsTexts.attendanceQueue.title);
    }
  }, [getAndSetResources, requestRouterResources]);

  useEffect(() => {
    if (requestRouterResources) {
      getAndSetResources(tabsTexts.attendanceQueue.title);

      sendStartTrack(userData.fullName || userData.name);
    }
  }, [getAndSetResources, requestRouterResources, userData]);

  const sendStartTrack = async (merchantName: string) => {
    const loggedAreaTracks = new LoggedAreaTracks();
    await loggedAreaTracks.sendTrackStartPlugin({ merchantName });
  };

  useEffect(() => {
    handleOpenAlertModify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousTab]);

  useEffect(() => {
    setInitialInputValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resources]);

  const validateQueues = (queues: Queue[]) => {
    let isValid = true;
    if (queues.length === 0) {
      isValid = false;
    } else {
      queues.forEach((queue) => {
        if (!validateTextInput(queue.name) || queue.emails.length === 0) {
          isValid = false;
        }
      });
    }
    return isValid;
  };

  useEffect(() => {
    if (previousTab === "Fila de atendimento") {
      setAttendanceQueue((current) => {
        return {
          ...current,
          isInvalid:
            !validateQueues(queueList) || !validateTextInput(preQueueMessage),
        };
      });
    }
  }, [queueList, preQueueMessage, previousTab]);

  const validateDays = (days: Day[], twentyFourHours: boolean) => {
    let isValid = true;

    if (!twentyFourHours) {
      isValid = isValid && validateHours(days);

      if (days[7].active) {
        if (days[7].holidays !== undefined) {
          isValid = isValid && validateDates(days[7].holidays);
        } else {
          return false;
        }
      }
    }
    return isValid;
  };

  useEffect(() => {
    if (previousTab === "Horários") {
      const days = [
        sunday,
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        holiday,
      ];
      setAttendanceHours((current) => {
        return {
          ...current,
          isInvalid: !validateDays(days, twentyFourHours),
        };
      });
    }
  }, [
    sunday,
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
    holiday,
    twentyFourHours,
    previousTab,
  ]);

  return (
    <MainContainer>
      {loadingResources ? (
        <LoadingContainer style={{ alignSelf: "center" }}>
          <bds-loading-spinner color="main" size="small" />
        </LoadingContainer>
      ) : (
        <>
          <bds-tabs align="left">
            <bds-tab
              group="attendance-queue"
              label={tabsTexts.attendanceQueue.title}
              onClick={() => handlePreviousTab("Fila de atendimento")}
            />

            <bds-tab
              group="attendance-pre-attendance"
              label={tabsTexts.preService.title}
              onClick={() => handlePreviousTab("Pré-atendimento")}
            />

            <bds-tab
              group="attendance-satisfaction-survey"
              label={tabsTexts.satisfactionSurvey.title}
              onClick={() =>
                handlePreviousTab(tabsTexts.satisfactionSurvey.title)
              }
            />

            <bds-tab
              group="attendance-opening-hours"
              label={tabsTexts.schedules.title}
              onClick={() => handlePreviousTab("Horários")}
            />
          </bds-tabs>

          <TabsPanelContainer>
            <OpeningHours />

            <AttendanceQueue
              setIsRemoved={setIsRemoved}
              idxRemoved={idxRemoved}
              setIdxRemoved={setIdxRemoved}
              setAttendanceQueue={setAttendanceQueue}
              isCanceledAction={isCanceledAction}
              setIsCanceledAction={setIsCanceledAction}
            />

            <PreServiceQuestion setPreServiceQuestion={setPreServiceQuestion} />

            <SatisfactionSurvey />
          </TabsPanelContainer>

          <ButtonsContainer>
            <SingleButtonContainer>
              <Button
                text={tabsTexts.cancel}
                isAdd={false}
                onClick={handleCancelAlert}
                isSubmit={false}
                isCancel
                isDisabled={tabsIsLoading()}
              />

              {tabsIsLoading() ? (
                <LoadingContainer>
                  <bds-loading-spinner color="main" size="small" />
                </LoadingContainer>
              ) : (
                <Button
                  text={tabsTexts.save}
                  isAdd={false}
                  onClick={handleSaveChanges}
                  isSubmit={false}
                  isDisabled={tabsIsInvalid()}
                />
              )}
            </SingleButtonContainer>
          </ButtonsContainer>

          <bds-alert open={cancelWarning}>
            <bds-alert-header variant="error" icon="warning">
              Deseja cancelar as alterações?
            </bds-alert-header>
            <bds-alert-body>{messageButtonCancel}</bds-alert-body>
            <bds-alert-actions>
              <bds-button variant="secondary" onClick={handleCancelChanges}>
                Sim
              </bds-button>
              <bds-button variant="secondary" onClick={handleCancelAlert}>
                Não
              </bds-button>
            </bds-alert-actions>
          </bds-alert>

          <WarningTab
            attendanceHours={attendanceHours}
            attendanceQueue={attendanceQueue}
            preServiceQuestion={preServiceQuestion}
          ></WarningTab>

          <AlertsTab
            attendanceHours={attendanceHours}
            attendanceQueue={attendanceQueue}
            preServiceQuestion={preServiceQuestion}
            setAttendanceHours={setAttendanceHours}
            setAttendanceQueue={setAttendanceQueue}
            setPreServiceQuestion={setPreServiceQuestion}
            handleReturnToPreviousTab={handleReturnToPreviousTab}
          ></AlertsTab>
        </>
      )}
    </MainContainer>
  );
};
