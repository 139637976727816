export const validateDates = (dates: string) => {
  let isValid = true;

  const dateRegex = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))$/i;

  const datesList = dates.split(",");
  if (datesList.length === 0) {
    return false;
  }
  datesList.forEach((date) => {
    isValid = isValid && !(date.match(dateRegex) === null);
  });

  return isValid;
};

export const formatListDates = (chips: string) => {
  const dateRegex = /([0-3]{1}[0-9]{1})\/([01]{1}[0-9]{1})/g;
  const result =
    (chips?.toString().match(dateRegex)?.toString() as string) || undefined;

  return result;
};
