import { useHumanServiceContext } from "src/contexts/HumanServiceContext";

import { ColumnContainer, InputsContainer, TextContainer } from "../styles";

import tabsTexts from "src/texts/tabsTexts.json";
import Switch from "../components/Switch";

import satisfactionSurveyGif from "src/assets/images/satisfactionSurvey.gif";
import { ImageContainer, Img, RowContainer } from "./styles";

export const SatisfactionSurvey = ({ }) => {
  const { satisfactionSurvey, setSatisfactionSurvey } =
    useHumanServiceContext();

  const handleSetSatisfactionSurvey = async (event: Event) => {
    const input = event.currentTarget as HTMLBdsSwitchElement | null;
    const value = await input?.getValue();

    setSatisfactionSurvey(value as boolean);
  };

  return (
    <bds-tab-panel class="groups" group="attendance-satisfaction-survey">
      <RowContainer>
        <ColumnContainer>
          <TextContainer>
            <bds-typo variant="fs-16" bold="semi-bold" tag="p">
              {tabsTexts.satisfactionSurvey.subTitle}
            </bds-typo>

            <bds-typo variant="fs-16" tag="p">
              {tabsTexts.satisfactionSurvey.info}
            </bds-typo>
          </TextContainer>

          <InputsContainer>
            <Switch
              text={tabsTexts.satisfactionSurvey.button}
              name="satisfactionSurvey"
              isEmojiSwitch={false}
              checked={satisfactionSurvey}
              onClick={handleSetSatisfactionSurvey}
            />
          </InputsContainer>
        </ColumnContainer>
        <ImageContainer>
          <Img src={satisfactionSurveyGif} alt="Take Blip" />
        </ImageContainer>
      </RowContainer>
    </bds-tab-panel>
  );
};
