export const validatePreServiceQuestion = (
  preServiceQuestionList: ({ [x: string]: string } | string)[]
) => {
  let isPassed = true;

  preServiceQuestionList.forEach((question) => {
    if (
      Object.values(question)[0] === undefined ||
      Object.values(question)[0] === "" ||
      question === undefined
    ) {
      isPassed = false;
    }
  });

  return isPassed;
};
