import { Day } from "src/types/Day";

export const setHoursMessage = (days: (string | Day)[][]) => {
  let msg = "";

  days.forEach((day) => {
    const onlyDay = day[1] as Day;

    const setMsg = (dayString: string) => {
      msg += `${dayString}:\n`;

      onlyDay.turns.forEach((turn) => {
        msg += `${turn.start} - ${turn.end}\n`;
      });

      msg += "\n";
    };

    const allValid = onlyDay.turns.map((turn) => {
      if (turn.end === "" && turn.start === "") {
        return false;
      } else {
        return true;
      }
    });

    if (allValid.includes(false) === false) {
      switch (day[0]) {
        case "Sunday":
          {
            setMsg("Domingo");
          }
          break;
        case "Monday":
          {
            setMsg("Segunda-feira");
          }
          break;
        case "Tuesday":
          {
            setMsg("Terça-feira");
          }
          break;
        case "Wednesday":
          {
            setMsg("Quarta-feira");
          }
          break;
        case "Thursday":
          {
            setMsg("Quinta-feira");
          }
          break;
        case "Friday":
          {
            setMsg("Sexta-feira");
          }
          break;
        case "Saturday":
          {
            setMsg("Sábado");
          }
          break;
      }
    }
  });

  return { msg };
};
