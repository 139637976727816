/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import AlertDelete from "../AlertDelete";
import Button from "../Button";
import InputChips from "../InputChips";
import InputText from "../InputText";

import { Queue } from "src/types/Queue";

import { MAX_ATTENDANCE_QUEUE } from "src/constants/Config";
import { useAppContext } from "src/contexts/AppContext";
import { Tabs } from "src/types/Tabs";
import {
  ButtonContainer,
  ButtonIconContainer,
  InputContainer,
  InputTextContainer,
  LabelContainer,
  MainContainer,
} from "./styles";

import { LoggedAreaTracks } from "../../services/trackings/handleSendTrackings";
import { Requests } from "src/services/requests/Requests";
import { getAttendanceQueues } from "../../services/getAttendanceQueues";
import tabsTexts from "src/texts/tabsTexts.json";

type QueueFieldsProps = {
  setQueueList: Dispatch<SetStateAction<Queue[]>>;
  setIdxRemoved: Dispatch<SetStateAction<number>>;
  setIsRemoved: Dispatch<SetStateAction<boolean>>;
  setTabData: Dispatch<SetStateAction<Tabs>>;
  idxRemoved: number;
  queueList: Queue[];
  setIsCanceledAction: Dispatch<SetStateAction<boolean>>;
  isCanceledAction: boolean;
  requestObject?: Requests;
};

export const QueueFields = ({
  setQueueList,
  queueList,
  setIdxRemoved,
  setIsRemoved,
  setTabData,
  setIsCanceledAction,
  isCanceledAction,
  requestObject,
}: QueueFieldsProps) => {
  const { userData } = useAppContext();
  const loggedAreaTracks = new LoggedAreaTracks();

  let list = [...queueList];
  const [isAlert, setIsAlert] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isModified, setIsModified] = useState(false);

  const [indexRemove, setIndexRemove] = useState<number | undefined>(undefined);

  const handleAdd = async () => {
    setQueueList([...queueList, { name: "", emails: [] }]);

    setIsModified(true);

    await loggedAreaTracks.sendTrackClickButtonAddQueue({
      merchantName: userData.Name,
      success: true,
      screenName: tabsTexts.attendanceQueue.title,
      error: "none",
    });
  };

  const handleClickDeleteButton = async (index: number) => {
    setIndexRemove(index);

    setIsAlert(true);
    setIsOpen(true);

    await loggedAreaTracks.sendTrackClickButtonDelete({
      merchantName: userData.Name,
      success: true,
      screenName: tabsTexts.attendanceQueue.title,
      error: "none",
      type: "queue",
    });
  };

  const handleRemove = async () => {
    // Set if is removed and the index of queue removed for remove the pre service question field too.
    setIsRemoved(true);
    setIdxRemoved(indexRemove || 0);

    const newList = queueList.filter((_, index) => index !== indexRemove);
    list.splice(indexRemove || 0, 1);

    setQueueList(newList);

    setIsModified(true);

    setIsOpen(false);
  };

  const handleOnChange = (event: Event, index: number) => {
    const { value } = event.target as HTMLBdsInputElement;

    if (value !== undefined && value !== null) {
      const newList = list.map((queue, i) => {
        if (index === i) {
          return {
            ...queue,
            name: value,
          };
        }
        return queue;
      });

      setQueueList(newList);
    }
  };

  const handleOnChangeChips = async (event: Event, index: number) => {
    const input = event.currentTarget as HTMLBdsInputChipsElement | null;
    const chips = await input?.get();

    if (chips) {
      const newList = list.map((queue, i) => {
        if (index === i) {
          return {
            ...queue,
            emails: chips as string[],
          };
        }
        return queue;
      });

      setQueueList(newList);
    }
  };

  useEffect(() => {
    if (isModified) {
      setTabData((current) => {
        return {
          ...current,
          isModified,
        };
      });
    }
  }, [isModified]);

  const handleGetAttendanceQueues = async () => {
    setQueueList([]);
    const teams = await getAttendanceQueues(requestObject as Requests);
    list = [...teams];

    setQueueList(teams);

    if (teams && teams.length && isCanceledAction) {
      list = [...teams];
      setIsCanceledAction(false);
    }
  };

  useEffect(() => {
    if (isCanceledAction) {
      handleGetAttendanceQueues();
    }
  }, [isCanceledAction]);

  const validateEmail = (queueList: string[]) => {
    let result = true;
    queueList.forEach((email: string) => {
      if (!email.includes("@") || !email.includes(".")) {
        result = false;
      }
    });
    return result;
  };

  return (
    <>
      {queueList &&
        queueList.map((queue, index) => {
          return (
            <MainContainer key={index}>
              <LabelContainer>
                <InputTextContainer queueListLength={queueList.length}>
                  <InputText
                    label={tabsTexts.attendanceQueue.queue.label}
                    value={queue.name}
                    onChange={(event) => {
                      handleOnChange(event, index);
                    }}
                    required={true}
                    requiredErrorMessage={tabsTexts.error.requiredField}
                  />
                </InputTextContainer>

                {queueList.length > 1 && (
                  <ButtonIconContainer>
                    <bds-button-icon
                      variant="ghost"
                      icon="trash"
                      onClick={() => handleClickDeleteButton(index)}
                    />
                  </ButtonIconContainer>
                )}
              </LabelContainer>

              <InputContainer>
                <InputChips
                  type="email"
                  label={tabsTexts.attendanceQueue.attendants.label}
                  chips={JSON.stringify(queue.emails, null, 1)}
                  onChange={(event) => handleOnChangeChips(event, index)}
                  helperMessage={tabsTexts.attendanceQueue.queue.instruction}
                  errorMessage={tabsTexts.attendanceQueue.queue.format}
                  danger={!validateEmail(queue.emails)}
                />
              </InputContainer>
            </MainContainer>
          );
        })}

      {queueList.length < MAX_ATTENDANCE_QUEUE && (
        <ButtonContainer>
          <Button
            isAdd
            text={tabsTexts.attendanceQueue.queue.add}
            onClick={handleAdd}
          />
        </ButtonContainer>
      )}

      {isAlert && (
        <AlertDelete
          handleRemove={handleRemove}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          merchantName={userData.Name}
          screenName={tabsTexts.attendanceQueue.title}
          type="queue"
        />
      )}
    </>
  );
};
