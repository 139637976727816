import { Queue } from "src/types/Queue";

export const validateQueue = (queueList: Queue[]) => {
  let isPassed = true;

  queueList.forEach((queue) => {
    if (queue.emails !== undefined && queue.name !== undefined) {
      if (queue.emails.length === 0 || queue.name === "") {
        isPassed = false;
      }

      queue.emails.forEach((email: string) => {
        const regexForValidateEmails =
          /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi;

        if (email.match(regexForValidateEmails) === null) {
          isPassed = false;
        }
      });
    } else {
      isPassed = false;
    }
  });

  return isPassed;
};
