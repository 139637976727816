import { useHumanServiceContext } from "src/contexts/HumanServiceContext";

import {
  AttendanceHoursContainer,
  ColumnContainer,
  RowContainer,
  TitleContainer,
} from "../styles";

import tabsTexts from "src/texts/tabsTexts.json";
import Switch from "../components/Switch";
import Attendance from "../components/Attendance";

export const OpeningHours = () => {
  const {
    twentyFourHours,
    setTwentyFourHours,
    sunday,
    setSunday,
    monday,
    setMonday,
    tuesday,
    setTuesday,
    wednesday,
    setWednesday,
    thursday,
    setThursday,
    friday,
    setFriday,
    saturday,
    setSaturday,
    holiday,
    setHoliday,
  } = useHumanServiceContext();

  const handleSetTwentyFourHours = async (event: Event) => {
    const input = event.currentTarget as HTMLBdsSwitchElement | null;
    const value = await input?.getValue();

    setTwentyFourHours(value as boolean);
  };

  return (
    <bds-tab-panel class="groups" group="attendance-opening-hours">
      <TitleContainer>
        <bds-typo variant="fs-16" tag="p" bold="semi-bold">
          {tabsTexts.schedules.info}
        </bds-typo>
      </TitleContainer>
      <RowContainer>
        <Switch
          text={tabsTexts.schedules.twentyFourHours}
          name="twentyFourHours"
          isEmojiSwitch={false}
          checked={twentyFourHours}
          onClick={handleSetTwentyFourHours}
        />
      </RowContainer>
      <AttendanceHoursContainer>
        {!twentyFourHours && (
          <>
            <ColumnContainer>
              <Attendance
                lastInput={false}
                isHolidays={false}
                name="Segunda"
                text={tabsTexts.schedules.days.monday}
                setDays={setMonday}
                day={monday}
              />

              <Attendance
                lastInput={false}
                isHolidays={false}
                name="Terça"
                text={tabsTexts.schedules.days.tuesday}
                setDays={setTuesday}
                day={tuesday}
              />

              <Attendance
                lastInput={false}
                isHolidays={false}
                name="Quarta"
                text={tabsTexts.schedules.days.wednesday}
                setDays={setWednesday}
                day={wednesday}
              />

              <Attendance
                lastInput={false}
                isHolidays={false}
                name="Quinta"
                text={tabsTexts.schedules.days.thursday}
                setDays={setThursday}
                day={thursday}
              />

              <Attendance
                lastInput={false}
                isHolidays={false}
                name="Sexta"
                text={tabsTexts.schedules.days.friday}
                setDays={setFriday}
                day={friday}
              />

              <Attendance
                lastInput={false}
                isHolidays={false}
                name="Sábado"
                text={tabsTexts.schedules.days.saturday}
                setDays={setSaturday}
                day={saturday}
              />

              <Attendance
                lastInput={true}
                isHolidays={false}
                name="Domingo"
                text={tabsTexts.schedules.days.sunday}
                setDays={setSunday}
                day={sunday}
              />
            </ColumnContainer>

            <ColumnContainer>
              <Attendance
                name="Feriados"
                text={tabsTexts.schedules.days.holiday}
                lastInput={true}
                setDays={setHoliday}
                isHolidays={true}
                day={holiday}
              />
            </ColumnContainer>
          </>
        )}
      </AttendanceHoursContainer>
    </bds-tab-panel>
  );
};
